import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import LandingImage from '../components/LandingImage';
import BiblestudyImage from "../components/BiblestudyImage";
import MapImage from '../components/MapImage';

const Index = () => (
    <Layout>
        <section className="pt-20 md:pt-40">
            <div className="container mx-auto px-8 lg:flex">
                <div className="text-center lg:text-left lg:w-1/2">
                    <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                        Welcome to Wasilla Baptist Church
                    </h1>
                    <p className="text-xl lg:text-2xl mt-6 font-light">
                        We are a Slavic Baptist Church located in Wasilla, Alaska.
                    </p>

                    {/* <p className="mt-8 md:mt-12">
            <Button size="lg">Get Started</Button>
          </p>*/}


                    {/*<p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p>*/}
                </div>
                <div className="lg:w-1/2">
                    <LandingImage/>
                </div>
            </div>
        </section>
        <section id="services" className="py-20 lg:pb-40 lg:pt-48">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl lg:text-5xl font-semibold">Weekly Services</h2>
                <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
                    <div className="flex-1 px-2">
                        <Card className="mb-8">
                            <p className="font-semibold text-xl">Sunday Services</p>
                            <p className="mt-4">
                                Mornings at 10:00am
                                <br/>
                                Evenings at 6:00pm
                            </p>
                        </Card>
                    </div>
                    <div className="flex-1 px-2">
                        <Card className="mb-8x">
                            <p className="font-semibold text-xl">Prayer Service</p>
                            <p className="mt-10">
                                Fridays at 7:00pm
                            </p>
                        </Card>
                    </div>
                    <div className="flex-1 px-2">
                        <Card className="mb-8">
                            <p className="font-semibold text-xl">Young Adult Biblestudy</p>
                            <p className="mt-10">
                                Mondays at 8:00pm
                            </p>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
        <SplitSection
            id="directions"
            primarySlot={
                <div className="lg:pr-32 xl:pr-48">
                    <h3 className="text-3xl font-semibold leading-tight">Directions</h3>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                        Address: 8350 Sunset Ave, Wasilla, AK 99623
                        <br/>
                        S Knik Goose Bay Rd & Sunset Ave
                    </p>
                </div>
            }
            secondarySlot={<MapImage/>}
        />
        <SplitSection
            reverseOrder
            primarySlot={
                <div className="lg:pl-32 xl:pl-30 text-justify">
                    <h3 className="text-3xl font-semibold leading-tight">
                        Young Adult Biblestudy
                    </h3>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                        Studying the Word of God is a vital part of a Christian's life. Without a solid understanding
                        of the Word of God, Christians don't have the foundation to defend their beliefs.
                        <br/> <br/>
                        Our Biblestudies are aimed at strengthening our understanding of the Word of God, discussing why
                        we believe what we believe, and looking into history to ensure that we can believe in the Bible
                        as a reliable witness and the true Words of God.
                    </p>
                </div>
            }
            secondarySlot={<BiblestudyImage/>}
        />
        <section id="contact" className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
            <h3 className="text-5xl font-semibold">Contact Us</h3>
            <p className="mt-8 text-xl font-light">
                Feel free to visit us in person or send us an email at <a
                href="mailto:contact@wasilla-slavic.org">contact@wasilla-slavic.org</a>
            </p>
            <p className="mt-8">
                <Button size="xl">Contact</Button>
            </p>
        </section>
    </Layout>
);

export default Index;
